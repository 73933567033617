@import './CommonColors.scss';


span.welcome-user {
  font-size: 16px;
  margin-left: 15px;
  margin-bottom: 15px;
  color: map-get($shades-of-grey, dark);
}

.dashboard {
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: stretch;
}
.dashboardNoNav {
  height: 100%;
  padding: 70px 30px 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: baseline;
  align-items: stretch;
}

/* User Header */
.dashboardProfileHeaderItemTitle {
  margin-right: 10 !important;
  display: inline-block !important;
  color: #4a545d !important;
  font-weight: 500;
  margin-bottom: 0 !important;
}
.dashboardProfileHeaderItemContent {
  margin-left: 6px !important;
  color: #656e76 !important;
}
.dashboardProfileHeaderEditButton {
  color: gray !important;
}
.dashboardProfileHeaderEditButton:hover {
  color: #af3947 !important;
}

/* Admin  */
#usersCertificationList tbody tr,
#usersCertificationList tbody td {
  cursor: pointer !important;
}

#usersCertificationList tbody tr:hover {
  background-color: #ffe0e3 !important;
  border-color: #ffe0e3 !important;
  color: #af3947 !important;
}

.ant-table-tbody > tr.ant-table-row-level-0:hover > td {
  background: #ffe0e3 !important;
}
