.adminSearchUsersTable {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1) !important;
  margin-bottom: 40px;
}
.adminSearchUsersTable .ant-table-tbody {
  background-color: white !important;
}
.adminSearchUsersTable .ant-table-tbody tr {
  border-radius: 0 !important;
}
.adminSearchUsersTable .ant-table-thead th {
  border-radius: 0 !important;
}

.adminSearchUsersTable .ant-table-pagination {
  margin-right: 5% !important;
}