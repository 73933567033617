@import './CommonColors.scss';

#isEditing-true {

}

span.upload-list-inline,
span.upload-list-inline.isUploadSuccess-true {
  div.ant-upload-list.ant-upload-list-picture {
    div.ant-upload-list-item.ant-upload-list-item-done,
    div.loading-file-done {
      border: 1px solid map-get($shades-of-green, main) !important;

      div.ant-upload-list-item-info {
        a {
          color: map-get($shades-of-green, main) !important;
        }
      }
    }
  }
}

span.upload-list-inline.isUploadSuccess-false {
  div.ant-upload-list.ant-upload-list-picture {
    div.ant-upload-list-item.ant-upload-list-item-undefined,
    div.loading-file-fail {
      border: 1px solid map-get($shades-of-red, main) !important;

      div.ant-upload-list-item-info {
        a {
          color: map-get($shades-of-red, main) !important;
        }
      }
    }
  }
}

.wh-upload-list .ant-upload-list-item-thumbnail {
  display: none;
  width: 0 !important;
}
.wh-upload-list .ant-upload-list-item-name {
  padding: 0;
}

$btn-duration: 0.35s;
$btn-duration-total: ($btn-duration * 4) * 2;

div.loading-file {
  display: inline-block;
  color: map-get($shades-of-grey, light);
  position: relative;
  border: none;

  &::before,
  &::after,
  span::before,
  span::after {
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    background: map-get($shades-of-grey, main);
    content: '';
    position: absolute;
    transition: opacity 1s ease;
  }

  &::before,
  &::after {
    height: 2px;
    width: 0%;
  }

  &::before {
    right: 0;
    top: 0;
  }

  &::after {
    bottom: 0;
    left: 0;
  }

  span::before,
  span::after {
    height: 0%;
    width: 2px;
  }

  span::before {
    left: 0;
    top: 0;
  }

  span::after {
    bottom: 0;
    right: 0;
  }

  // &:hover {
  &::before,
  &::after,
  span::before,
  span::after {
    opacity: 1;
  }

  &::before {
    animation-name: btn-border-top;
    animation-duration: $btn-duration-total;
  }

  &::after {
    animation-name: btn-border-bottom;
    animation-delay: $btn-duration * 2;
    animation-duration: $btn-duration-total;
  }

  span::before {
    animation-name: btn-border-span-left;
    animation-delay: $btn-duration;
    animation-duration: $btn-duration-total;
  }

  span::after {
    animation-name: btn-border-span-right;
    animation-delay: $btn-duration * 3;
    animation-duration: $btn-duration-total;
  }
  // }
}

@mixin btnAnimations($name, $anchor, $edge) {
  $anchor-op: 'left';

  @if $anchor == 'left' {
    $anchor-op: 'right';
  }
  @elseif $anchor == 'top' {
    $anchor-op: 'bottom';
  }
  @elseif $anchor == 'bottom' {
    $anchor-op: 'top';
  }

  @keyframes #{$name} {
    0% {
      #{$anchor-op}: auto;
      #{$anchor}: 0;
      #{$edge}: 0%;
    }
    12.5% {
      #{$anchor-op}: auto;
      #{$anchor}: 0;
      #{$edge}: 100%;
    }
    12.6% {
      #{$anchor-op}: 0;
      #{$anchor}: auto;
    }
    50% {
      #{$edge}: 100%;
    }
    62.5% {
      #{$edge}: 0%;
    }
    100% {
      #{$anchor-op}: 0;
      #{$anchor}: auto;
      #{$edge}: 0%;
    }
  }
}

@include btnAnimations(btn-border-top, 'right', 'width');
@include btnAnimations(btn-border-bottom, 'left', 'width');
@include btnAnimations(btn-border-span-left, 'top', 'height');
@include btnAnimations(btn-border-span-right, 'bottom', 'height');
