.auditButtonContainer {
  left: -30px;
  position: absolute !important;
  top: calc(50% - 50px) !important;
}
.auditButton {
  width: 30px !important;
  padding: 0;
  border-right-width: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  min-width: 0 !important;
  height: 100px !important;
}
.auditIconButton {
  // margin-left: -10px;
  font-size: 20px;
}

.auditDrawer {
  margin-top: 120px;
  height: calc(100% - 120px) !important;
  .icon {

  }
  .iconCol {
    align-self: flex-start;
  }
  .textCol {
    margin-left: 10px;
  }
  .title {
    font-weight: 500;
  }
  .subtitle {

  }
  .ant-drawer-body {
    padding-top: 10px;
  }
}

.auditRefreshButton {
  padding: 0;
  border-width: 0 !important;
  margin-top: -10px !important;
  padding: 0 !important;
  &:active, &:hover, &:focus {
    color: black !important;
  }
}
