@import './CommonColors.scss';

// Graphs row
.mainGraphRow {

}
// Graph loading
.graphLoad {
  margin-left: 40% !important;
  margin-top: 120px !important;
}
.graphLoad .anticon {
  font-size: 100px !important;
}
// Graph emtpy
.graphEmpty {
  margin-top: 60px !important;
}
// Graph column - aka. card
.graphColumn {
  width: 40% !important;
  min-width: 520px !important;
  height: 380px !important;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1) !important;
  border-radius: 6px;
  margin-bottom: 30px;
  margin-right: 15px;
  margin-left: 15px;
}
.graphTitleRow {
  padding: 20px 0 0 30px !important;
}
.graphTitle {
  font-weight: 600;
  font-size: 18px;
  color: map-get($shades-of-grey, darker);
}
.graphSubtitleRow {
  padding: 10px 0 0 50px !important;
}
.graphSubtitle {
  font-weight: 500;
  font-size: 16px !important;
}
.graphSubtitleNumber {
  color: map-get($shades-of-grey, darker) !important;
}
.graphSubtitlePostfix {
  color: map-get($shades-of-grey, dark) !important;
  padding-left: 20px;
}
.graph {
  width: 100% !important;
  height: 340px !important;
}


// Filter
.filterAdminDashboardCol {
  margin-right: 100px !important;
  padding-bottom: 20px;
}
