@import './CommonColors.scss';

.authorizationContainer {
  height: 100%;
}
.authorizationBoxContainer {
  border: 1px solid map-get($shades-of-grey, light);
  border-radius: 15px;
  -webkit-box-shadow: 3px 4px 22px -4px rgba(0, 0, 0, 0.53);
  -moz-box-shadow: 3px 4px 22px -4px rgba(0, 0, 0, 0.53);
  box-shadow: 3px 4px 22px -4px rgba(0, 0, 0, 0.53);
  min-width: 300px;
  max-width: 300px;
  z-index: 10;
  opacity: .97;
  background-color: map-get($shades-of-white, main) !important;
}
.authorizationBox {
  margin: 30px 30px 20px 30px;
}
.authorizationBoxTitle {
  font-size: 18px;
  font-weight: 600;
  color: map-get($shades-of-grey, darker) !important;
}
.authorizationBoxDescription {
  margin-bottom: 15px;
  font-weight: 400;
  font-size: 14px;
  color: map-get($shades-of-grey, darker) !important;
}
.authorizationForm {
  margin-top: 20px !important;
  margin-bottom: 30px !important;
}
.authorizationFormInput {
  height: 50px !important;
  align-items: center;
}
.authorizationCreateAccountLink {
  color: map-get($shades-of-grey, dark) !important;
  font-size: 15px;

  &:hover {
    color: map-get($shades-of-grey, darker) !important;
  }
}

.authorizationLoginButton {
  margin-left: 8px !important;
  height: 34px !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  width: 80px !important;
  color: map-get($shades-of-white, main) !important;
  background-color: map-get($shades-of-grey, dark) !important;
  border: 0 !important;
  border-radius: 5px !important;

  &:hover, &:active, &:focus {
    background-color: map-get($shades-of-grey, darker) !important;
    outline: 0;
    color: white !important;
  }
}

.loginBackgroundImage {
  position: fixed;
  height: 343px;
  background-size: 100%;
  min-width: 100%;
  z-index: 0;
}
