@import './CommonColors.scss';

.filterMenu .ant-dropdown-menu-item,
.filterMenu .ant-dropdown-menu-submenu-title,
.filterSubmenuMenu .ant-dropdown-menu-item {
  font-size: 12px !important;
  padding: 2px 8px 2px 8px !important;
}
.filterLabel {
  color: map-get($shades-of-grey, darker) !important;  
}
