.dashboardWorkHoursCol {
  margin-top: -15px;
}
.dashboardAdditionalButton {
  color: gray !important;
}
.dashboardAdditionalButton .anticon {
  font-size: 18px !important;
}
.dashboardAdditionalButton:hover {
  color: #af3947 !important;
}
