.employerInputContainer {
  padding-left: 10px;
  padding-bottom: 34px;
  border: 1px solid #dedede;
  box-shadow: none !important;
  outline: 0 !important;
  border-radius: 8px !important;
  height: 32px;
  max-width: 500px;
  .swapButton {
    margin-left: 10px;
    margin-top: 5px;
  }
}