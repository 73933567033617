.printCardModal {

}
.printCardModal .ant-modal-body {
  min-width: 40vw;
}
.printCardForm {}
.printCardFormBackground {
  width: 100%;
  max-width: 500px;
}
.printCardElements {
  position: absolute;
}
.printCardElementName {
  position: absolute;
  margin-top: 60px;
  text-align: left;
  margin-left: -78px;
  font-size: 16px;
  font-weight: 600;
}
.printCardElementCertName {
  position: absolute;
  margin-top: 94px;
  text-align: left;
  margin-left: -33px;
  width: 250px;

  ul {
    list-style: none;

    &, li {
      margin: 0;
      padding: 0;
    }

    li {
      font-weight: 400;
      font-size: 12px;

      span {
        font-size: 10px;
      }
    }
  }
}
.printCardElementCertNumber {
  position: absolute;
  margin-top: 26px;
  font-weight: 600;
  margin-left: 360px;
  font-size: 1.3em;
}
.printCardElementEffectiveDate {
  position: absolute;
  margin-top: 267.5px;
  font-weight: 600;
  margin-left: 370px;
  font-size: 0.7em;
}
.printCardElementExpiryDate {
  position: absolute;
  margin-top: 284px;
  font-weight: 600;
  margin-left: 370px;
  font-size: 0.7em;
}
.printCardSubmitButton {
  margin-left: 20px;
}
