@import './CommonColors.scss';

.rescheduleRulesIcon {
  margin-top: 52px;
}
.selectedRescheduleRule::before {
  content: "➤";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: map-get($shades-of-red, main); /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
  font-size: 20px;

}
.selectedRescheduleRule {
  list-style: none !important; /* Remove default bullets */
}
