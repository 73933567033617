/* Steps */
.paymentSteps {
  max-width: 980px;
}
/* Step icon content */
.paymentSteps .ant-steps-icon {
  color: #af3947 !important;
  font-weight: 500;
}
.paymentSteps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.25) !important;
}
.paymentSteps .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: white !important;
}
/* Step icon background */
.paymentSteps .ant-steps-item-process .ant-steps-item-icon {
  background-color: #af3947 !important;
}
.paymentSteps .ant-steps-item-icon > .ant-steps-icon,
.paymentSteps .ant-steps-item-process .ant-steps-item-icon,
.paymentSteps .ant-steps-item-finish .ant-steps-item-icon {
  border-color: #af3947 !important;
}
/* Step line */
.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: #af3947 !important;
}
/* Buttons */
.paymentConfirmButton {
  margin-left: 20px;
}
.saveAsPDFButton {
  margin-right: 20px;
}
/* Voucher */
.fixedInputButton_Voucher {
  margin-top: 43px;
  margin-left: -6px;
  border-left: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
