@import './CommonColors.scss';

.userInfoPopoverContent {
  width: 440px;
}
.userHeader {
  font-size: 24px;
  font-weight: 400;
  margin-left: 15px;
  margin-bottom: 15px;
  margin-left: 70px;
  margin-top: -19px !important;
  color: map-get($shades-of-grey, dark);
}
.userHeaderJustCard {
  font-size: 24px;
  font-weight: 400;
  margin-top: -20px !important;
  color: map-get($shades-of-grey, dark);
}
.userHeaderIcon {
  margin-left: 10px;
  font-size: 18px;
}
.avatarIcon {
  margin-top: 10px !important;
}
