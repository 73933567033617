@import './CommonColors.scss';

.filterAuditRow {}
.filterMenuAudit .ant-dropdown-menu-item {
  font-size: 12px !important;
  padding: 2px 8px 2px 8px !important;
}
.filterAuditLabel {
  color: map-get($shades-of-grey, darker) !important;
  margin-top: -10px !important;
}
