.importUsersTableRow {
  border-top-width: 1px;
  border-top-color: grey;
  border-top-style: solid;
  margin: 30px 20px 20px 20px;
  padding-top: 10px;
  .passValIcon {
    margin-left: 10px;
    color: green;
  }
  .failValIcon {
    margin-left: 10px;
    color: red;
  }

  //
  .importWIPIcon {
    color: blue;
  }
  .importSkipIcon {
    color: blue;
  }
  .importCheckIcon {
    color: green;
  }
  .importFailIcon {
    color: red;
  }
}
.importImportInfoParagraph {
  margin-bottom: 2px !important;
}
.importRow {
  margin-top: 30px;
}
