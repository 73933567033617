.userProfileIDMContainer {
  margin-top: 10px !important;
  margin-bottom: 0 !important;
}
.userProfileFormContainer {
  margin-top: -1px !important;
  margin-bottom: 20px !important;
}
.userProfileForm {
}
.userProfileAvatar {
  float: left !important;
  margin: 0 !important;
}
.userProfileAvatar {
  float: left !important;
  margin: 0 !important;
}
.userProfileAvatar .ant-upload:hover {
  border-color: #af3947 !important;
}
.editProfileButton:hover {
  border-color: rgba(0, 0, 0, 0.25) !important;
  color: #af3947 !important;
}
.saveProfileButton:hover {
  border-color: rgba(0, 0, 0, 0.25) !important;
  color: #af3947 !important;
}

/* tile uploaded pictures */
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}
