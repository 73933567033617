.registerContainer {
  height: 100%;
}
.registerBoxContainer {
  border: 1px solid #dadce0;
  border-radius: 8px;
  -webkit-box-shadow: 3px 4px 15px -4px rgba(0, 0, 0, 0.43);
  -moz-box-shadow: 3px 4px 15px -4px rgba(0, 0, 0, 0.43);
  box-shadow: 3px 4px 15px -4px rgba(0, 0, 0, 0.43);
}
.registerBox {
  margin: 30px 30px 20px 30px;
}
.registerBoxTitle {
  font-weight: 600;
}
.registerBoxDescription {
  margin-bottom: 15px;
  font-weight: 400;
}
.registerForm {
  margin-top: 40px !important;
}

.registerButton {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 20px !important;
  font-weight: 500 !important;
  width: 150px !important;
  height: 40px !important;
}

.resetConfirmationButton {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 20px !important;
  font-weight: 500 !important;
  width: 200px !important;
  height: 40px !important;
}

#agreementItemForm
  > div.ant-row.ant-form-item
  > div.ant-col.ant-form-item-control-wrapper
  > div.ant-form-item-control.has-success
  > span.ant-form-item-children
  > div.ant-row
  > div.ant-col {
  line-height: 25px !important;
}
