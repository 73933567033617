@import './CommonColors.scss';
//
.advisorDashboardRow {

}
// Graph loading
.advDashboardSectionLoad {
  margin-left: 40% !important;
  margin-top: 120px !important;
}
.advDashboardSectionLoad .anticon {
  font-size: 100px !important;
}
// Graph emtpy
.advDashboardSectionEmpty {
  margin-top: 60px !important;
}
// Graph column - aka. card
.advDashboardSectionColumn {
  width: 40% !important;
  min-width: 520px !important;
  height: 60vh !important;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1) !important;
  border-radius: 6px;
  margin-bottom: 30px;
  margin-right: 15px;
  margin-left: 15px;
}
.advDashboardSectionTitleRow {
  padding: 20px 0 0 30px !important;
}
.advDashboardSectionTitle {
  font-weight: 600;
  font-size: 18px;
  color: map-get($shades-of-grey, darker);
}
.advDashboardSectionSubtitleRow {
  padding: 10px 0 0 50px !important;
}
.advDashboardSectionSubtitle {
  font-weight: 500;
  font-size: 16px !important;
}
.advDashboardSectionTable {
  width: 100%;
  height: 100%;
  margin: 10px 8px 0px 10px;
}
.advisorScheduledTable, .advisorReadyToScheduleTable {

}
