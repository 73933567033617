.contactUsModal {

}
.contactUsModal .ant-modal-body {
  min-width: 50vw;
}
.contactUsForm {

}
.contactUsSubmitButton {
  margin-left: 20px;
}
