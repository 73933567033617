@import './CommonColors.scss';

body {
  padding: 0;
  // min-width: 700px !important;
}

section {
  background-color: map-get($shades-of-grey, lighter) !important;
}

/* #headers stuff */
.appHeader .ant-row-flex {
  height: 100% !important;
}


.main-content {
  overflow: auto !important;
  margin-bottom: 60px;
}

.appHeaderFullscreen, .appHeader, header.appHeader {
  box-shadow: 2px -1px 9px 3px rgba(0, 0, 0, 0.2);
  z-index: 1000 !important;
  min-height: 120px !important;
  background: map-get($shades-of-grey, light) !important;
  border-bottom: 1.3px solid map-get($shades-of-grey, main);
}


div.ant-col.menu-icon  {
  background: map-get($shades-of-grey, light) !important;
}

div.ant-col.menu-icon i {
  font-size: 22px !important;
}

.headingLogo {
  float: left;
  height: 80px !important;
}

.headingLogo.authorized {
  margin-left: 110px;
}

.headingTitleLogo {
  height: 80px;
  margin-top: 3px;
  margin-left: 102px;
}
.headingTitleLogoFixed {
  height: 80px;
  margin-top: 3px;
  margin-left: 160px;
}

/* User Badge */
.idmBadgeContainer {
  background-color: black !important;
  border-color: black !important;
  margin-right: 20px !important;
  background-color: transparent !important;
  border-color: transparent !important;

  .idmBadgeUserIcon {
    font-size: 16px !important;
    color: white !important;
    color: black !important;
    background-color: map-get($shades-of-grey, dark) !important;
    box-shadow: 2px -1px 9px 3px rgba(0, 0, 0, 0.2);

    &:hover {
      background-color: map-get($shades-of-grey, darker) !important;
    }

    .idmBadgeUserText {
      color: map-get($shades-of-white, main) !important;
    }
  }

}

// Layout
section.container-layout {
  height: 100vh !important;
}



/* .startProcessButton {
  height: 54px !important;
  width: 54px !important;
  background: linear-gradient(to bottom, #e56972, #7a0020) !important;
  border: 0 !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.5) !important;
} */

/* Navigation */
.sideNavigation {
  padding-top: 20px !important;
}

.ant-layout-sider-trigger {
  background: linear-gradient(to bottom, #af3947, #7a0020) !important;
}

.ant-layout-sider-trigger > i > svg {
  color: #fff !important;
}

.ant-menu-item:hover {
  color: #af3947 !important;
}

.ant-menu-item.ant-menu-item-selected {
  background-color: #ffe0e3 !important;
  color: #af3947 !important;
}

.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #7a0020 !important;
}

.ant-layout-sider-light  {
  z-index: 1000 !important;
}

ul.sideNavigation {
  border-right:  1.3px solid map-get($shades-of-grey, light) !important;
  box-shadow: 0 0px 6px 0 rgba(0, 0, 0, 0.2) !important;
}

ul.sideNavigation li:last-child:not(:only-of-type) {
  max-width: 224.7px;
  position: fixed;
  bottom: 0;
  left: 0;
}
/* Page - w/ nav and header */
.pageHeader {
  margin-top: 10px !important;
  border-bottom-width: 1px;
}
.pageHeaderNoHeight {
  width: 100px;
}
.pageHeaderNoHeight .ant-page-header-heading {
  position: absolute;
  z-index: 99 !important;


}
.pageContent {
  padding: 30px;
  height: 100%;
}
.pageContentSubsection {
  padding: 10px 50px 10px 50px;
}
/* Page - fullscreen */
.pageContentFullScreen {
  padding: 30px;
  height: 100%;
  background-color: map-get($shades-of-grey, lighter) !important;
}

/* Alert */
.alertController {
  z-index: 9999;
}

/* Default logo */
.logoCCPO {
  height: 80px;
  margin-bottom: 20px;
}
.bwLogo {
  height: 50px;
  margin-bottom: 20px;
}

/* Footer */
.appFooter {
  height: 30px !important;
  background: map-get($shades-of-grey, lighter) !important;
  font-weight: 500;
  font-size: 12px;
  padding-bottom: 40px;
  padding-top: 20px;
  padding-right: 20px;
  z-index: 20;
  width: 260px;
  border-radius: 6px;
  right: 0 !important;
  bottom: 0;
  position: absolute;

  .appFooterColumn {
    color: map-get($shades-of-grey, darker) !important;
    margin-right: 10px !important;
  }

  .appFooterLogo {
    height: 24px;
    width: 24px;
    margin: 0 15px;
  }
}

// Commons styles - this should go to a separated file one day
.fixedInputCheckbox {
  margin-left: 34px !important;
}
