@import './CommonColors.scss';

.commonSearchBar {
  margin-bottom: 40px;
}
.searchBarRow {
  background-color: white;
  padding: 15px 0 15px 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.1) !important;
  width: 70%;
  min-width: 700px !important;
}
.searchBarInput {
  .ant-input-suffix {
    display: none !important;
  }
  input {
    padding-top: 16px;
    line-height: 25px;
    font-size: 16px !important;
    border: 0 !important;
    background: none !important;
  }
 }
.searchBarIcon {
  font-size: 28px;
  margin-right: 50px;
  margin-left: 20px;
}
/* Buttons */
.searchButton {
  font-weight: 500;
  margin-right: 40px;
}